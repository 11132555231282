import './styles/styles.css';
import { ComingSoon } from './pages';

function App() {
  return (
    <div className="App">
      <ComingSoon />
    </div>
  );
}

export default App;
