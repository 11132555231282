import lottie from 'lottie-web';
import React from 'react';
import { useEffect, useRef } from 'react';
import ComingSoonAnimation from '../assets/lottie_animations/coming-soon.json';

const ComingSoon = () => {
    useEffect(() => {
        lottie.loadAnimation({
            container: container.current,
			render: 'svg',
			loop: true,
			autoplay: true,
			animationData: ComingSoonAnimation
        });
    }, []);

    const container = useRef(null);

    return (
        <div className="page coming-soon-page">
            <h1>Singularity Space</h1>
            <div className="animation-container" ref={container}></div>
        </div>
    )
}

// export default ComingSoon
export default React.memo(ComingSoon);